<template>
  <div class="joy-app-container large">
    <div class="insurance-book book-wrap">
      <div class="insurance-mod-group">
        <div class="insurance-cell-header">
          <h4>保险预订</h4>
          <span>订单号: {{ this.orderNo }}</span>
        </div>
        <div class="insurance-cell-body">
          <div class="ins-tab" v-if="!loading">
            <div class="ins-notice">
              如果您往返航班不超过7天，在购买7天航意险时只需为去程航班购买保险，勿需为返程购买保险（7天航意险自动包含7天内的返程航班保险）
            </div>
            <a-tabs type="card" v-model="activeTripIdx" @change="onTabChange">
              <a-tab-pane
                v-for="(trip, index) in trips"
                :key="index"
                :tab="trip.segmentName"
              ></a-tab-pane>
            </a-tabs>
            <div class="tab-content">
              <!-- <keep-alive> -->
                <template v-for="(trip, index) in trips">
                  <InsurancePassengerTable
                    :ref="`insPassTable${index}`"
                    :key="index"
                    v-show="activeTripIdx === index"
                    :trip="trip"
                    @change="onInsChange"
                  ></InsurancePassengerTable>
                </template>
              <!-- </keep-alive> -->
            </div>
          </div>
          <div class="loading" v-else>
            <a-spin size="large"></a-spin>
          </div>
        </div>
      </div>
      <div class="insurance-mod-group">
        <div class="insurance-cell-header">
          <h4>购物车</h4>
        </div>
        <div class="insurance-cell-body">
          <InsuranceCartTable :list="cartData" @delete="onDelete"></InsuranceCartTable>
        </div>
        <div class="ins-amount">总计：￥{{ totalAmount }}</div>
        <div style="text-align: center; margin-bottom: 20px">
          <div>
            <a-checkbox v-model="agree">
              已阅读产品购买须知《幸福航空保险购买须知》
            </a-checkbox>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 20px">
          <a-button type="primary" size="large" @click="onOrder" :loading="submitting">确认购买</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsurancePassengerTable from './components/insurance-passenger-table.vue'
import InsuranceCartTable from './components/insurance-cart-table.vue'
import queryString from 'query-string'
import _ from 'lodash'
import { airCity } from '@/filters/flight'

import { fetchOrderDetail } from '@/api/order'
import { insuranceOfferQuery, insuranceOrderPlace } from '@/api/insurance'

import moment from 'moment'

export default {
  name: 'InsuranceBook',
  components: {
    InsurancePassengerTable,
    InsuranceCartTable
  },
  computed: {
    currentTrip () {
      return this.trips[this.activeTripIdx]
    }
  },
  watch: {
    activeTripIdx (index) {
      if (this.trips[index].prods.length) {
        return
      }
      this.queryPrice()
    }
  },
  data () {
    return {
      agree: false,
      activeTripIdx: 0,
      orderNo: '',
      loading: false,
      trips: [
        // {
        //   segment: {},
        //   segmentName: '',
        //   passengers: [],
        //   prods: [],
        //   selected: []
        // }
      ],
      orderDetail: {},
      currentProds: [],
      cartData: [],
      totalAmount: 0,
      submitting: false
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.orderNo = params.orderNo
    this.loading = true

    this.fetchDetail(this.orderNo)
  },
  methods: {
    async fetchDetail (orderNo) {
      try {
        const data = await fetchOrderDetail({
          orderNo: orderNo
        })

        this.orderDetail = data.bizData
        this.initTrip()
        this.queryPrice()
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    async queryPrice () {
      const { segment } = this.currentTrip

      const params = {
        businessParams: JSON.stringify({
          airline: segment.carrier,
          flightNo: segment.flightNo,
          depCode: segment.depCode,
          arrCode: segment.arrCode,
          flightTime: moment(segment.flightDate).format('HH:mm'),
          flightDay: moment(segment.flightDate).format('YYYY-MM-DD')
        }),
        productType: 'INSURANCE',
        publishType: 'SELF_BIZ',
        serviceTime: segment.flightDate
      }

      try {
        const data = await insuranceOfferQuery(params)

        this.parseInsurance(data)

        this.loading = false

        this.$nextTick(() => {
          this.$refs['insPassTable' + this.activeTripIdx] && this.$refs['insPassTable' + this.activeTripIdx][0].init()
        })
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    parseInsurance (data) {
      const { offers } = data

      const { offerItems } = offers[0]

      const prods = []

      offerItems.forEach((offerItem) => {
        const { fareDetails, serviceDTOs, offerItemID } = offerItem

        const serviceDTO = serviceDTOs[0]

        const fareDetail = _.find(fareDetails, (item) => {
          return item.serviceRefs.indexOf(serviceDTO.serviceID) !== -1
        })

        const netFare = _.find(fareDetail.fares, (item) => {
          return item.code === 'NetFare'
        })

        let prodDetail = null

        if (serviceDTO.serviceType === 'INSURANCE') {
          prodDetail = serviceDTO.insurances[0]
        }

        prods.push({
          serviceDTO,
          fareDetail,
          offerItemID,
          netFare,
          prodDetail
        })
      })

      this.trips[this.activeTripIdx].prods = prods
    },
    initTrip () {
      const { segments, passengers, ticketPnrPassengerInfos, orderItems } = this.orderDetail

      this.trips = segments.map((seg, index) => {
        const depCodeCn = airCity(seg.depCode, true)
        const arrCodeCn = airCity(seg.arrCode, true)

        const tripPassengers = _.cloneDeep(passengers)

        const { orderItemServices } = orderItems[index]

        const { ticketServiceDetails } = orderItemServices[0]

        tripPassengers.forEach((pass) => {
          const service = ticketServiceDetails.find((service) => {
            return service.passengerName === pass.passengerName
          })

          pass.certNo = service.certNo

          pass.pnrInfo = ticketPnrPassengerInfos.find((pnr) => {
            return pnr.passengerName === pass.passengerName
          })
        })

        const trip = {
          index: index,
          segment: seg,
          segmentName: depCodeCn + '-' + arrCodeCn,
          passengers: tripPassengers,
          prods: [],
          selected: []
        }

        return trip
      })
    },
    onTabChange (index) {
      this.activeTripIdx = index
    },
    onInsChange (trip, passenger, prod, passegners) {
      this.trips[trip.index].selected = passegners.filter((item) => {
        return item.prodSelect.offerItemId !== 0
      })
      this.freshCart()
    },
    freshCart () {
      const cartData = []

      this.trips.forEach((trip) => {
        if (trip.selected && trip.selected.length) {
          trip.selected.forEach((passenger) => {
            cartData.push(passenger)
          })
        }
      })

      this.totalAmount = 0

      this.cartData = cartData

      this.cartData.forEach((item) => {
        this.totalAmount += item.prodAmount
      })
    },
    onOrder () {
      if (!this.cartData.length) {
        return this.$message.warning('请先选择要购买的产品')
      }

      if (!this.agree) {
        return this.$message.warning('请先阅读并同意购买须知')
      }

      this.submitProd()
    },
    async submitProd () {
      this.submitting = true
      console.log('ccc', this.cartData)
      const orderItems = this.cartData.map((pass) => {
        return {
          offerItemId: pass.prodSelect.offerItemId,
          details: {
            passengers: [
              {
                ticketNo: pass.pnrInfo.ticketNo,
                pnrNo: pass.pnrInfo.pnr,
                name: pass.pnrInfo.passengerName,
                segments: [
                  {
                    airline: pass.segment.airline,
                    depCode: pass.segment.depCode,
                    arrCode: pass.segment.arrCode,
                    depTime: pass.segment.depTime
                  }
                ]
              }
            ]
          }
        }
      })

      const params = {
        outOrderNo: this.orderNo,
        orderItems
      }

      try {
        const result = await insuranceOrderPlace(params)
        this.submitting = false
        this.$message.success('保险购买成功')
        this.$router.push({ path: '/orderManager/insuranceDetail', query: { id: result } })
      } catch (err) {
        this.submitting = false
        this.$message.error(err.message)
      }
    },
    onDelete (passengerProd) {
      const { pnrInfo, segment } = passengerProd
      const { ticketNo } = pnrInfo

      let tripIndex = ''

      this.trips.forEach((item, index) => {
        const { segment: tripSegment } = item
        if (tripSegment.depCode === segment.depCode) {
          tripIndex = index
        }
      })

      this.$refs['insPassTable' + tripIndex][0].deletePassengerProd(ticketNo, segment)
    }
  }
}
</script>

<style lang="less" scoped>
.book-wrap {
  padding: 20px 0;

  .loading {
    margin: 100px 0;
    text-align: center;
  }
}

.insurance-mod-group {
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;

  .insurance-cell-header {
    padding: 0 28px;
    height: 48px;
    line-height: 48px;
    background-color: rgba(0, 66, 142, 0.08);

    h4 {
      display: inline-block;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 700;
      color: #323232;
    }
  }

  .insurance-cell-body {
    padding: 15px 20px;
  }

  .insurance-cell-footer {
    padding: 20px 45px;
    border-top: 1px solid #e5e5e5;
  }
}

.ins-notice {
  margin-bottom: 10px;
  color: red;
}

.ins-amount {
  padding: 0 20px;
  font-size: 14px;
}
</style>
