<template>
  <a-table
    rowKey="ticketNo"
    :data-source="list"
    :columns="columns"
    :pagination="false"
    bordered
  >
    <span slot="index" slot-scope="text, record, index">
      {{ index + 1 }}
    </span>
    <span slot="action" slot-scope="text, record">
      <a-button type="link" @click="onDetail(record)">提取票号</a-button>
    </span>
  </a-table>
</template>

<script>
import { getDert } from '@/api/order'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '票号',
          dataIndex: 'ticketNo'
        },
        {
          title: 'PNR编码',
          dataIndex: 'pnr'
        },
        {
          title: '姓名',
          dataIndex: 'passengerName'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    submit () {
      return Promise.resolve()
    },
    async onDetail (record) {
      console.log('rc', record)
      try {
        const data = await getDert({
          airlineCode: this.$airSetttings.airline,
          onlyOpen: false,
          ticketNo: record.ticketNo,
          pnrNo: record.pnr,
          psgName: record.passengerName
        })
        this.$info({
          class: 'ticket-modal',
          content: (
            <div class="ticket-green-box" domPropsInnerHTML={ data.ticketInfos[0].remark.replace(/\r\n/g, '<br/>').replace(/\s{1}/g, '&nbsp;&nbsp;') }></div>
          ),
          width: '60%'
        })
      } catch (err) {
        this.$message.error(err.message)
      }
    }
  }
}
</script>

<style lang="less">
.b2b-detail-modify-price {
  .ant-table {
    margin-bottom: 16px;
  }

  .ant-table-body tr > td {
    padding: 0 16px;
  }
}

.ticket-modal {
  .ant-modal-confirm-body {
    .anticon {
      display: none;
    }

    .ticket-green-box {
      background: #008040;
      color: #fff;
      padding: 10px;
    }
  }
}
</style>
